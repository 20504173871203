import React from "react";
import PropTypes from "prop-types";
import Text from "@ui/text";
import Button from "@ui/button";
import Heading from "@ui/heading";
import Image from "@ui/image";
import { HeadingType, TextType, ButtonType, ImageType } from "@utils/types";
import {
  HeroWrapper,
  HeroWrapperText,
  HeroWrapperTextTop,
  HeroWrapperTextBottom,
  HerologosList,
  HerologosItem,
  StyledBG
} from "./style";
import { Col, Container, Row } from "react-bootstrap";
import { PageHeaderImgWrap } from "../../page-header/layout-01/style";
import { StaticImage } from "gatsby-plugin-image";
import GlobalRating from '@components/global-rating' 
// import { Link } from "gatsby";
const HeroArea = ({ data }) => {
console.log(data,"data")
  const tabActive = (id) => {
    if (!!document.getElementById(id)) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };
  const handleLinkClick = (e) => {
    e.preventDefault();
    window.open('https://www.g2.com/products/andovar/references/localization-solutions', '_blank', 'noopener,noreferrer');
};

  return (
    <HeroWrapper>
      <Container>
        <Row className="h-100">
          <Col lg={7}>
            <HeroWrapperText>
              <HeroWrapperTextTop>
                <Heading as="h3">{data.texts[0]?.content}</Heading>
                <Heading
                  as="h1"
                  dangerouslySetInnerHTML={{ __html: data.texts[1]?.content }}
                ></Heading>
                <Heading>{data.texts[3]?.content}</Heading>
                {data?.buttons.slice(0, 1)?.map(({ id, content, ...rest }) => (
                  <Button key={id} m="7px" {...rest}>
                    {content}
                  </Button>
                ))}
                {data?.buttons
                  .slice(1, 2)
                  ?.map(({ path, content, ...rest }) => (
                    <Button onClick={() => tabActive(path)} m="7px" {...rest} key={path}>
                      {content}
                    </Button>
                  ))}
              </HeroWrapperTextTop>
              {/* <HeroWrapperTextBottom>
                <Heading as="h5" className="text-white">
                  {data.texts[3]?.content}
                </Heading>
                <Text>
                  <span>{data.texts[4]?.content}</span>
                </Text>
              </HeroWrapperTextBottom> */}
              <GlobalRating pageHeader={data?.id}></GlobalRating>
              <HerologosList>
                {data &&
                  data.items[0].images.map((el, index) => (
                    <HerologosItem key={index} className="logo-box">
                      <Image src={el.src} alt={el.alt || "Logo"} />
                    </HerologosItem>
                  ))}
              </HerologosList>
            </HeroWrapperText>
          
          </Col>
          <Col lg={5}>
            <PageHeaderImgWrap>
              <StyledBG> 
              {data &&
                  data.items[3].images.map((el, index) => (
                      <Image key={index} src={el.src} alt={el.alt || "Logo"} />
                  ))}
                {/* <div
                  className="gatsby-iframe"
                ><iframe
                  src='https://www.g2.com/categories/document-translation/grids.embed?filters%5Bcompany_segment%5D=&wid=1719217204'
                  id='g2-crowd-widget-default'
                  frameborder='0'
                  scrolling='no'
                  >
                  </iframe>
                  <a href="https://www.g2.com/categories/document-translation"
                    >
                    G2 Grid® for Document Translation Services
                  </a>
                </div> */}
              </StyledBG>
             
             
            </PageHeaderImgWrap>
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
};

HeroArea.propTypes = {
  data: PropTypes.shape({
    headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
    texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
    images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
  }),
};

export default HeroArea;
