import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Anchor from "@ui/anchor";
import Text from "@ui/text";
import Heading from "@ui/heading";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";

import {
  LocalizeWrapper,
  LocalizeInner,
  LocalizeContentbox,
  LocalizeContentboxInner,
  LocalizeIconwrap,
  // LocalizeTextwrap,
} from "./style";

const LocalizeGrid = ({ data }) => {
  return (
    <LocalizeWrapper id={data.section}>
      <LocalizeInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LocalizeContentbox>
                {data &&
                  data.items.map((el, index) => (
                      <LocalizeContentboxInner className="flipcard" key={index}>
                        <LocalizeIconwrap className="flipFront">
                          <Image
                            src={el.images[0].src}
                            alt={el.images[0]?.alt || "Icon"}
                          />
                          {(!! el.subject?
                            <Heading as="h6">{el.subject}</Heading> : ""
                          )}
                          <Heading as="h4">{el.title}</Heading>
                          <Text>{el.description}</Text>
                        </LocalizeIconwrap>

                        <LocalizeIconwrap className="flipBack">
                          {(!! el.subject?
                            <Heading as="h6">{el.subject}</Heading> : ""
                          )}
                          <Heading as="h4">{el.title}</Heading>
                          <Text>{el.description}</Text>
                          <Anchor key={index} path={el.path} target="_blank">Learn more</Anchor>
                        </LocalizeIconwrap>
                        {/* <LocalizeTextwrap className="flipBack">
                        <Text>{el.description}</Text>
                        <Anchor path={el.path}>
                          Learn More
                          <i className="ms-2 fal fa-angle-right"></i>
                        </Anchor>
                      </LocalizeTextwrap> */}
                      </LocalizeContentboxInner>
                  ))}
              </LocalizeContentbox>
            </Col>
          </Row>
        </Container>
      </LocalizeInner>
    </LocalizeWrapper>
  );
};
export default LocalizeGrid;
