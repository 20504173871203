import styled, { themeGet, device } from "@styled";

export const LocalizeWrapper = styled.section`
  padding: 50px 0 100px;
  ${device.medium} {
    padding: 60px 0 100px;
  }
  ${device.xlarge} {
    padding: 70px 0 100px;
  }
`;

export const LocalizeInner = styled.div``;
export const LocalizeContentbox = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  margin-top: 80px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(4, 1fr);
  }
  a {
    display: inherit;
    &:hover {
      color: inherit;
    }
  }
`;
export const LocalizeContentboxInner = styled.div`
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(0 0 0 / 30%);
    transform: translateY(-3px);

    .flipBack {
      top: 0;
      background: rgb(35 9 90);
    }
  }

  .flipFront, .flipBack {
    width: 100%;
    height: 100%;
  }

  .flipBack {
      top: -100%;
      position: absolute;
  }

  .flipBack h4, .flipBack p {
      color: #fff;
  }

  .flipBack a {
      color: #fff !important;
      border: 1px solid #fff;
      padding: 0px 36px;
      border-radius: 15px;
      font-size: 18px;
      line-height: 54px;
      height: 54px;
      transform: translateY(0px);
      transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
  }

  .flipBack a:hover {
      transform: translateY(-3px);
  }
`;

export const LocalizeIconwrap = styled.div`
  padding: 30px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: ${themeGet("transition")};
  backface-visibility: hidden;
  position: relative;
  background: #fff;
  height: 100%;
  z-index: 2;
  width: 100%;
  h6 {
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 10px;
    color: #343434;
    font-weight: normal;
  }
  h4 {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    ${device.large} {
      font-size: 22px;
    }
  }
  .gatsby-image-wrapper {
    height: 60px;
    margin-bottom: 20px;
    img {
      object-fit: contain !important;
    }
  }
`;
export const LocalizeTextwrap = styled.div`
  padding: 30px 24px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  transition: ${themeGet("transition")};
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  p {
    color: #fff;
    ${device.large} {
      font-size: 14px;
    }
  }
  a {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #fa4f19;
    font-weight: bold;
    text-decoration: none;
    ${device.large} {
      font-size: 14px;
    }
  }
`;
